
@import "~antd/dist/antd.less";
// Primary
@primary-color: #18a689;

// Border color
@border-color-base: #c9d3dd; // base border outline a component

// Outline
@outline-width: 2px;

// inputs
@input-height-base: 38px;

// Layout
@layout-body-background      : #2f4050;

// Base Scaffolding Variables
// ---
// Background color for `<body>`
@body-background        : @layout-body-background;
// Base background color for most components
@component-background   : #fff;
@text-color             : #676a6c;
@font-family-no-number  : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family            : "open sans, Helvetica Neue, Helvetica, Arial, sans-serif", @font-family-no-number;
@code-family            : Consolas, Menlo, Courier, monospace;
@font-size-base         : 13px;

// Buttons
@btn-height-base: 38px;
