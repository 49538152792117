.captcha-style {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5001;
    overflow: hidden;
    background: url("../../../theme/assets/img/tela-login.jpg") no-repeat center center fixed;
    background-size: cover;
    background-color: #18a689;
    height: 100vh;
    width: 100vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}
