/* Resolve conflito com antd*/
.ant-modal-close-x {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around;
}

.badge-yellow {
  background-color: #ffc107;
  color: #fff;
}

.truncate-cliente-escritorio {
  color: rgb(223, 228, 237);
  margin: 5px;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
  width: 155px;
}

.ant-transfer-list-search-action {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* improviso correção posição engrenagem */
.ant-menu-item,
.ant-menu-submenu-title {
  /* margin: -1.6rem -1.1rem !important; */
  color: #999c9e !important;
}

/* corrige posição da progressbar*/
.ant-row-flex {
  align-items: baseline;
}

.menu-eng {
  display: flex;
  justify-content: center;
  align-items: baseline;
  right: 0.1rem;
  bottom: 0.2rem;
  position: relative;
}

.pw-sm {
  padding: 0 3px;
}

.pl-0 {
  padding-left: 0 !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.ant-dropmenu-overlay {
  height: 166px;
  overflow: auto;
}

.ant-table table,
table.table.table-responsive {
  font-size: 13px !important;
}

.profile-img-container {
  display: flex;
  justify-content: center;
}

h1,
h2,
h3 {
  margin-top: 20px !important;
  font-weight: 100 !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.minimalize-styl-2 {
  padding: 7px 12px !important;
}

.ant-list-item-content {
  align-items: baseline;
}
/* */

.fs-13,
.fs-13.pull-right {
  font-size: 13px !important;
}

div > div.panel.panel-primary.filtro-dashboard {
  position: absolute !important;
  z-index: 300 !important;
  overflow: hidden;
}

label.control-label,
div.form-group > label.control-label {
  color: #676a6c !important;
  font-size: 13px;
}

/* Fim resolve conflito ant*/

/*popover*/
.pt-dark {
  background-color: 'red';
}
/* inputs */

.form-control,
.single-line {
  border: 1px solid #c9d3dd;
  border-radius: 4px;
  height: 38px;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  height: 38px;
}

input[type='text']:disabled {
  background-color: transparent !important;
}
.card-statistics {
  font-size: 40px;
  font-weight: 800 !important;
}

.loadmore {
  border-radius: 0 !important;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox-helper,
.version-date {
  font-size: 11px;
  color: #757677;
}
.checkbox-helper {
  margin-left: 24px;
}

.text-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.text-warning {
  color: #f8ac59 !important;
}

.text-primary {
  color: #19b394 !important;
}

.swal2-popup {
  font-size: 1.4rem !important;
}
.swal2-content {
  color: #545454;
  font-size: 16px;
}
.no-border {
  border-color: transparent !important;
}

.hp-15 {
  padding: 0 15px !important;
}

/* react-widget */
/* .rw-select>* {
    width: 100% !important;
    margin-right: 15px !important;
    height: 99% !important;
} */

/* react-widget - DropdownList */
/* .rw-widget-container{
    border: 1px solid #c9d3dd !important;
} */
.rw-dropdown-list-input {
  height: 38px !important;
}

/* react-widget - DateTimePicker */
.rw-widget > .rw-widget-container {
  height: 38px !important;
}

.rw-widget-picker > .rw-select {
  width: 5% !important;
}

/**/
.table-no-border tr {
  height: 46px !important;
}

.table-no-border tr td {
  vertical-align: middle !important;
  border: none !important;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.table-no-content, .table-content-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 337px;
}

.nc-title, .tl-title {
  color: #484848;
  font-size: 22px;
  font-weight: 800;
  padding: 5px;
}

.nc-message {
  font-size: 14px;
  color: #ccc;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tarefa-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.vertical-align {
  display: flex;
  align-items: center;
}
/**/
.Select-control {
  height: 38px !important;
  border: 1px solid #c9d3dd !important;
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 38px !important;
}

.Select-input > input {
  line-height: 22px !important;
}

.switch.on.switchBg {
  background: #18a689;
}

.swal-button--confirm {
  background-color: #18a689 !important;
}

.Select-menu-outer {
  z-index: 1000;
}

.white {
  color: #fff;
}
.bg-login {
  background-image: url('../img/tela-login.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.vertical-center {
  min-height: 50%; /* Fallback for browsers do NOT support vh unit */
  min-height: 50vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.gc-list-check {
  list-style: none;
  padding: 10px 0;
  margin: 0;
}

.gc-list-check .gc-list-check-item {
  margin: 10px 0;
  padding-left: 30px;
  background-image: url('../img/icons/check-blue-light.png');
  background-repeat: no-repeat;
  background-position: 0 4px;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 350px;
}

/* ibox-content */
.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

.ibox-content h5 {
  padding-top: 10px;
  font-size: 16px;
}

/** ANTD Paginação Table **/
.ant-table-pagination.ant-pagination {
  margin-right: 30px !important;
}

/** ANTD Espaçamento Table **/
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 10px !important;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.swal-button--cancel.button-custom {
  color: #ffffff !important;
  background-color: #ed5565 !important;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent !important;
}

.div-text-grid {
  text-align: left;
}

.ant-transfer {
  text-align: center !important;
}

.ant-transfer-list-content-item {
  text-align: left !important;
}

.ant-transfer-list-header {
  text-align: left !important;
}

.color-link-editar {
  color: #676a6c;
}

.ant-tabs-nav {
  height: 60px;
}

.ant-collapse-content {
  overflow: unset !important;
  color: #101213;
}

.ant-collapse {
  color: #0d1011;
}

.ant-collapse-header {
  color: #0d1011;
}

.panel-primary {
  color: #0d1011;
  overflow: unset;
}

.react-loading {
  z-index: 5000;
  position: fixed;
  background: #8360c3; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #18a689, #8360c3); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #18a689,
    #8360c3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.ant-calendar-month-panel {
  height: 250px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.text-bold {
  font-weight: 700 !important;
}

.fs-14 {
  font-size: 14px !important;
}
.visualizado-text {
  padding: 5px 0;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer !important;
}

.fs-10 {
  font-size: 10px !important;
}

.text-navy h1,
.text-navy h2,
.text-navy h3,
.text-navy h4,
.text-navy h5,
.text-navy h6 {
  color: #1ab394 !important;
}

.text-primary h1,
.text-primary h2,
.text-primary h3,
.text-primary h4,
.text-primary h5,
.text-primary h6 {
  color: inherit !important;
}

.text-success h1,
.text-success h2,
.text-success h3,
.text-success h4,
.text-success h5,
.text-success h6 {
  color: #1c84c6 !important;
}
.text-info h1,
.text-info h2,
.text-info h3,
.text-info h4,
.text-info h5,
.text-info h6 {
  color: #23c6c8 !important;
}

.text-warning h1,
.text-warning h2,
.text-warning h3,
.text-warning h4,
.text-warning h5,
.text-warning h6 {
  color: #f8ac59 !important;
}
.text-danger h1,
.text-danger h2,
.text-danger h3,
.text-danger h4,
.text-danger h5,
.text-danger h6 {
  color: #ed5565 !important;
}
.text-muted h1,
.text-muted h2,
.text-muted h3,
.text-muted h4,
.text-muted h5,
.text-muted h6 {
  color: #888888 !important;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6 {
  color: #ffffff !important;
}

.navbar-form-custom .form-control {
  z-index: auto;
}

.mt-2 {
  margin-top: 2rem !important;
}

.p-0 {
  padding: 0 !important;
}
.button-distance {
  display: flex;
  justify-content: space-evenly;
  flex-shrink: 1;
}

.drop-over-downward td {
  border-bottom: 2px dashed #1ab394 !important;
}

.drop-over-upward td {
  border-top: 2px dashed #1ab394 !important;
}
.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mv-1 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.float-right {
  float: right !important;
}

.panel-heading a {
  cursor: pointer;
  margin-left: 5px;
  color: white;
}

.label-cursor {
  cursor: pointer;
}

.bg-danger {
  background-color: #ed5565 !important;
}

.lg-label {
  font-size: 20px;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 100 !important;
}

.ant-drawer-title {
  color: #484848 !important;
  font-weight: 700 !important;
  letter-spacing: 0.05em !important;
}

.ant-drawer-body {
  padding: 10px !important;
}

.ft-details-title {
  letter-spacing: 0.08rem;
  font-size: inherit;
  font-weight: 700;
  color: #484848;
  text-transform: uppercase;
}

.fc-tundora {
  color: #484848 !important;
}

.ft-details-label {
  font-size: 1em;
  font-weight: 600;
  padding-bottom: 5px;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-flex-end {
  justify-content: flex-end !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

.total-text-pg {
  display: flex;
  height: 28px;
  padding: 6px;
  align-items: center;
}
.pagination-actions {
  display: flex;
  justify-content: space-between;
  margin-top: -25px;
}

.ft-bg-fixado {
  background-color: #fef9ec;
}

/* style checkbox */
.cbx {
  position: relative;
  top: 1px;
  width: 17px;
  height: 17px;
  border: 1px solid #c8ccd4;
  border-radius: 3px;
  vertical-align: middle;
  transition: background 0.1s ease;
  cursor: pointer;
}

.xs-scroll::-webkit-scrollbar {
  width: 5px;
}

.xs-scroll::-webkit-scrollbar-track {
  background: #ddd;
}

.xs-scroll::-webkit-scrollbar-thumb {
  background: #666;
}

.hidden-xs-up {
  display: none;
}
.cbx:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 5px;
  width: 5px;
  height: 11px;
  opacity: 0;
  transform: rotate(45deg) scale(0);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transition: all 0.3s ease;
  transition-delay: 0.15s;
}
.lbl {
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
}

.circle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

input[type='checkbox']:checked ~ .cbx {
  border-color: transparent;
  background: #1ab394;
  animation: jelly 0.6s ease;
}

input[type='checkbox']:disabled ~ .cbx {
  opacity: 0.6;
}

input[type='checkbox']:checked ~ .cbx:after {
  opacity: 1;
  transform: rotate(45deg) scale(1);
}

@-moz-keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
@-o-keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
@keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
